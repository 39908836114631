import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Metric } from '@app/models/metric';

@Injectable({ providedIn: 'root' })
export class MetricService {
    httpOptions = {
        headers: new HttpHeaders({ 
          'Access-Control-Allow-Origin':'*'
        })
    };

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Metric[]>(`${environment.apiUrl}/metrics`, this.httpOptions);
    }

    get(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/metric/${id}`, this.httpOptions);
    }

    getStats(id: number) {
        return this.http.get<Metric[]>(`${environment.apiUrl}/metric/stats/${id}`, this.httpOptions);
    }

    getUserStats(id: number) {
        return this.http.get<Metric[]>(`${environment.apiUrl}/metric/user-stats/${id}`, this.httpOptions);
    }

    getRating(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/metric/rating/${id}`, this.httpOptions);
    }

    create(name: string) {
        return this.http.post<any>(`${environment.apiUrl}/metrics`, { name }, this.httpOptions);
    }

    update(id: number, name: string) {
        return this.http.post<any>(`${environment.apiUrl}/metric/update/${id}`, { name }, this.httpOptions);
    }
}
