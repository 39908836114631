import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { fakeBackendProvider } from './_helpers';
import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';

import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AboutComponent } from './pages/about/about.component';
import { TopComponent } from './pages/top/top.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { MetricsComponent } from './pages/metrics/metrics.component';
import { TestMetricComponent } from './pages/test-metric/test-metric.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { StatMetricComponent } from './pages/stat-metric/stat-metric.component';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        appRoutingModule
    ],
    declarations: [
        AppComponent,
        AboutComponent,
        TopComponent,
        LoginComponent,
        SignupComponent,
        ProfileComponent,
        MetricsComponent,
        TestMetricComponent,
        StatMetricComponent,
        AddItemComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
