import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Item, RawItem } from '@app/models/item';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ItemService {
    httpOptions = {
        headers: new HttpHeaders({ 
          'Access-Control-Allow-Origin':'*'
        })
    };

    constructor(private http: HttpClient) { }

    public getNext(metricId: number): Observable<Item> {
        return this.http.post<RawItem>(
            `${environment.apiUrl}/items/next`,
            { metric_id: metricId }, this.httpOptions
        ).pipe(
            map(res => {
                if (!res.item_JSON) {
                    return res as any;
                }
                const rawItem = new RawItem(res);
                const item = rawItem.retrieveItem();
                return item;
            })
        );
    }

    public answer(answer: string, metricId: number) {
        return this.http.post<any>(
            `${environment.apiUrl}/items/answer`,
            { answer, metric_id: metricId }, this.httpOptions
        );
    }

    public create(metricId: number, itemJSON: string, type: number, difficulty: number, rightAnswer: string) {
        return this.http.post<RawItem>(
            `${environment.apiUrl}/items/create`,
            {
                metric_id: metricId,
                item_JSON: itemJSON,
                type,
                difficulty,
                right_answer: rightAnswer
            }, this.httpOptions
        );
    }

    public get(itemId: number) {
        return this.http.get<RawItem>(`${environment.apiUrl}/items/${itemId}`, this.httpOptions).pipe(
            map((data) => {
                const raw = new RawItem(data);
                return raw;
            })
        );
    }

    public update(itemId: number, itemJSON: string, type: number, difficulty: number, rightAnswer: string) {
        return this.http.put<RawItem>(
            `${environment.apiUrl}/items/update/${itemId}`,
            {
                item_JSON: itemJSON,
                type,
                difficulty,
                right_answer: rightAnswer
            }, this.httpOptions
        );
    }
}
