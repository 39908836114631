import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/models/user';

@Injectable({ providedIn: 'root' })
export class UserService {
    httpOptions = {
        headers: new HttpHeaders({ 
          'Access-Control-Allow-Origin':'*'
        })
    };

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`, this.httpOptions);
    }

    getTopRated(metricId: number) {
        return this.http.get<User[]>(`${environment.apiUrl}/users/top/${metricId}`, this.httpOptions);
    }

    signup(username: string, email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/signup`, { username, email, password }, this.httpOptions);
    }

    update(id: number, username: string, email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/update/${id}`, { username, email, password }, this.httpOptions);
    }
}
