import { Component, OnInit, OnDestroy } from '@angular/core';
import { Metric } from '@app/models/metric';
import { MetricService } from '@app/services/metric.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ItemService } from '@app/services/item.service';
import { Item } from '@app/models/item';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
    templateUrl: 'test-metric.component.html',
    styleUrls: ['test-metric.component.css']
})
export class TestMetricComponent implements OnInit, OnDestroy {
    public id: number;
    public loading = false;
    public rating: number = null;
    public interval: number = null;
    public metric: Metric;
    public currentItem: Item;
    public currentAnswer: string;
    public isAuthenticated = false;
    public timeout = null;
    public timeoutHint = false;
    public timeoutHint2 = false;
    private paramSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        private metricService: MetricService,
        private itemService: ItemService,
        private authService: AuthenticationService
    ) { }

    ngOnInit() {
        this.loading = true;
        this.paramSub = this.route.params.pipe(
            filter((params) => params.metric_id)
        ).subscribe(params => {
            // проверка авторизации пользователя, сброс токена при ошибке
            this.authService.refreshToken().subscribe((res) => {
                this.isAuthenticated = (res !== null);
                this.initialInfo(+params.metric_id);
            });
        });
    }

    ngOnDestroy() {
        this.paramSub.unsubscribe();
    }

    public answer() {
        this.loading = true;
        this.itemService.answer(this.currentAnswer, this.metric.id).subscribe(() => {
            this.getNextItem();
            this.loading = false;
        });
    }

    public skip() {
        this.currentAnswer = '/&SKIP#/';
        this.answer();
    }

    public stop() {
        this.loading = true;
        this.metricService.getRating(this.metric.id).subscribe((res: any) => {
            this.rating = res.rating;
            this.interval = res.sme ? Math.round(res.sme) : 0;
            this.loading = false;
        });
    }

    private initialInfo(metricId: number) {
        this.metricService.get(metricId).subscribe((metric) => {
            this.metric = metric;
            this.loading = false;
            this.getNextItem();
        });
    }

    private getNextItem() {
        this.loading = true;
        this.currentAnswer = null;
        this.itemService.getNext(this.metric.id).subscribe((res: any) => {
            this.rating = res.rating;
            this.interval = res.sme ? Math.round(res.sme) : 0;
            this.currentItem = res;
            this.loading = false;
            clearTimeout(this.timeout);
            this.timeoutHint = false;
            this.timeoutHint2 = false;
            if (res.avarageTime) {
                this.timeout = setTimeout(() => {
                    this.timeoutHint = true;
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        this.timeoutHint = false;
                        this.timeoutHint2 = true;
                    }, 1000 * res.avarageTime);
                }, 1000 * res.avarageTime);
            }
        });
    }
}
