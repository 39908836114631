import { Routes, RouterModule } from '@angular/router';

import { TopComponent } from './pages/top/top.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { AuthGuard } from './helpers/auth.guard';
import { ProfileComponent } from './pages/profile/profile.component';
import { MetricsComponent } from './pages/metrics/metrics.component';
import { TestMetricComponent } from './pages/test-metric/test-metric.component';
import { StatMetricComponent } from './pages/stat-metric/stat-metric.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { AboutComponent } from './pages/about/about.component';

const routes: Routes = [
    { path: '', component: MetricsComponent },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'metrics', component: MetricsComponent },
    { path: 'test-metric/:metric_id', component: TestMetricComponent },
    { path: 'stat-metric/:metric_id', component: StatMetricComponent },
    { path: 'top/:metric_id', component: TopComponent },
    { path: 'about', component: AboutComponent },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'add-item', component: AddItemComponent, canActivate: [AuthGuard] },
    { path: 'edit-item/:item_id', component: AddItemComponent, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);
