import { Component, OnInit } from '@angular/core';
import { Metric } from '@app/models/metric';
import { MetricService } from '@app/services/metric.service';

@Component({
    templateUrl: 'metrics.component.html',
    styleUrls: ['metrics.component.css', '../../common.css']
})
export class MetricsComponent implements OnInit {
    public loading = false;
    public metrics: Metric[];

    constructor(private metricService: MetricService) { }

    ngOnInit() {
        this.loading = true;
        this.metricService.getAll().subscribe(metrics => {
            this.loading = false;
            this.metrics = metrics;
        });
    }
}
