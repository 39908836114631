import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { UserService } from '@app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { MetricService } from '@app/services/metric.service';
import { Metric } from '@app/models/metric';

@Component({
    templateUrl: 'top.component.html'
})
export class TopComponent implements OnInit {
    public loading = false;
    public users: User[];

    constructor(
        private userService: UserService,
        private metricService: MetricService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.loading = true;
        this.route.params.pipe(
            filter((params) => params.metric_id),
            switchMap((params) => this.metricService.get(params.metric_id))
        ).subscribe((metric: Metric) => {
            this.userService.getTopRated(metric.id).subscribe(users => {
                this.loading = false;
                this.users = users;
            });
        });
    }
}
