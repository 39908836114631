import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css']
})
export class AppComponent {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    public isAuthorized(): boolean {
        return !!this.authenticationService.currentToken;
    }

    public logout() {
        this.authenticationService.logout().subscribe(() => {
            this.router.navigate(['/login']);
        }, () => {
            this.router.navigate(['/login']);
        });
    }

    public login() {
        this.router.navigate(['/login']);
    }

    public signup() {
        this.router.navigate(['/signup']);
    }
}
