export class Item {
    id: number;
    metricId: number;
    authorId: number;
    type: number;
    question: string;
    choices?: string[];
    rightAnswer?: string;
    difficulty?: number;
    editable?: boolean;
    avarageTime?: number;
}

export class RawItem {
    id: number;
    item_JSON: string;
    metric_id: number;
    author_id: number;
    type: number;
    difficulty?: number;
    right_answer?: string;
    editable?: boolean;
    avarage_time: number;

    constructor(data: any) {
        this.id = data.id;
        this.item_JSON = data.item_JSON;
        this.metric_id = data.metric_id;
        this.author_id = data.author_id;
        this.difficulty = data.difficulty;
        this.editable = data.editable;
        this.type = data.type;
        this.right_answer = data.right_answer;
        this.avarage_time = data.avarage_time;
    }

    public retrieveItem(): Item {
        const itemJSON = JSON.parse(this.item_JSON);
        const item = new Item();
        item.id = this.id;
        item.metricId = this.metric_id;
        item.authorId = this.author_id;
        item.type = this.type;
        item.difficulty = this.difficulty;
        item.rightAnswer = this.right_answer;
        item.editable = this.editable;
        item.avarageTime = this.avarage_time;
        item.question = itemJSON.question;
        if (itemJSON.choices) {
            item.choices = itemJSON.choices;
        }
        return item;
    }
}

