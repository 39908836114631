import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { UserService } from '@app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MetricService } from '@app/services/metric.service';
import { Subscription } from 'rxjs';
import { Metric } from '@app/models/metric';
import { ItemService } from '@app/services/item.service';

@Component({
    templateUrl: 'stat-metric.component.html',
    styleUrls: ['../../common.css']
})
export class StatMetricComponent implements OnInit {
    public loading = false;
    public users: User[];
    public metric: Metric;
    public stats: any;
    public userStats: any;
    private paramSub: Subscription;

    constructor(
        private metricService: MetricService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.loading = true;
        this.paramSub = this.route.params.pipe(
            filter((params) => params.metric_id)
        ).subscribe(params => {
            this.initialInfo(+params.metric_id);
        });
    }

    private initialInfo(metricId: number) {
        this.metricService.get(metricId).subscribe((metric) => {
            this.metric = metric;
            this.loading = false;
            this.getStats();
        });
    }

    private getStats() {
        this.loading = true;
        this.metricService.getStats(this.metric.id).subscribe((res: any) => {
            this.stats = res;
            this.loading = false;
        });
        this.metricService.getUserStats(this.metric.id).subscribe((res: any) => {
            this.userStats = (res) ? res[0] : {};
            this.loading = false;
        });
    }
}
