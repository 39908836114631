import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { UserService } from '@app/services/user.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'profile.component.html'
})
export class ProfileComponent implements OnInit {
    public profileForm: FormGroup;
    public loading = false;
    public changesSaved = false;
    public error = '';
    public user: User;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.profileForm = this.formBuilder.group({
            username: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            password: ['', Validators.minLength(6)]
        });
        this.loading = true;
        this.authenticationService.getUser().subscribe((user: User) => {
            if (!user) {
                this.router.navigate(['/']);
                return;
            }
            this.loading = false;
            this.user = user;
            this.profileForm.patchValue({
                username: user.username,
                email: user.email,
                password: user.password
            });
        });
    }

    get form() { return this.profileForm.controls; }

    public onSubmit() {
        if (this.profileForm.invalid) {
            return;
        }
        this.changesSaved = false;
        this.loading = true;
        this.userService.update(this.user.id, this.form.username.value, this.form.email.value, this.form.password.value).subscribe(
            (data) => {
                this.changesSaved = true;
                this.loading = false;
            },
            error => {
                this.error = error;
                this.loading = false;
            }
        );
    }
}