import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '@app/services/authentication.service';
import { UserService } from '@app/services/user.service';

@Component({ templateUrl: 'signup.component.html' })
export class SignupComponent implements OnInit {
    public signupForm: FormGroup;
    public loading = false;
    public submitted = false;
    public returnUrl: string;
    public error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) {
        // TODO: make it in guard?!
        if (this.authenticationService.currentToken) {
            // already logged in
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.signupForm = this.formBuilder.group({
            username: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: this.mustMatch('password', 'confirmPassword')
        });

        this.returnUrl = this.route.snapshot.queryParams.redirect || '/';
    }

    get form() { return this.signupForm.controls; }

    public onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.signupForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.signup(this.form.username.value, this.form.email.value, this.form.password.value)
            .pipe(
                first(),
                switchMap(() => this.authenticationService.login(this.form.email.value, this.form.password.value))
            )
            .subscribe(
                (data) => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
    }

    private mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}
