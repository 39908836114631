import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { AuthenticationService } from '@app/services/authentication.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ItemService } from '@app/services/item.service';
import { Item, RawItem } from '@app/models/item';
import { MetricService } from '@app/services/metric.service';
import { Metric } from '@app/models/metric';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';

@Component({
    templateUrl: 'add-item.component.html'
})
export class AddItemComponent implements OnInit {
    public itemForm: FormGroup;
    public loading = false;
    public changesSaved = false;
    public error = '';
    public newItem: Item;
    public authorId: number = null;
    public metrics: Metric[];
    public newChoices: string[] = ['', '', '', '', '', '', '', ''];
    public rightAnswer: string;
    public itemId: number = null;
    private paramSub: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private itemService: ItemService,
        private metricService: MetricService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.itemForm = this.formBuilder.group({
            question: ['', Validators.required],
            metricId: ['', Validators.required],
            rightAnswer: ['', Validators.required],
            difficulty: ['', Validators.pattern('\\d+')],
            type: ['', Validators.required]
        });
        this.loading = true;
        this.metricService.getAll().subscribe(metrics => {
            this.loading = false;
            this.metrics = metrics.filter((metric: any) => metric.rating > 1500);
        });
        this.paramSub = this.route.params.pipe(
            filter((params) => params.item_id),
            switchMap((params) => this.itemService.get(+params.item_id))
        ).subscribe((rawItem: RawItem) => {
            const item = rawItem.retrieveItem();
            this.itemForm.controls['question'].setValue(item.question);
            this.itemForm.controls['metricId'].setValue(item.metricId);
            this.itemForm.controls['metricId'].disable();
            this.itemForm.controls['difficulty'].setValue(item.difficulty);
            this.itemForm.controls['type'].setValue(item.type);
            this.itemForm.controls['rightAnswer'].setValue(item.rightAnswer);
            if (item.choices && item.choices.length > 0) {
                this.newChoices = item.choices;
                this.newChoices.push('');
            }
            this.itemId = item.id;
        });
    }

    get form() { return this.itemForm.controls; }

    public onSubmit() {
        if (this.itemForm.invalid) {
            return;
        }
        const itemObj: any = {
            question: this.form.question.value
        };
        const choices = this.newChoices.filter(choice => choice.trim());
        if (choices.length > 0 && String(this.form.type.value) === '0') {
            itemObj.choices = choices;
        }
        this.changesSaved = false;
        this.loading = true;
        const request = (this.itemId) ?
            this.itemService.update(
                this.itemId,
                JSON.stringify(itemObj),
                this.form.type.value,
                this.form.difficulty.value,
                this.form.rightAnswer.value
            ) :
            this.itemService.create(
                this.form.metricId.value,
                JSON.stringify(itemObj),
                this.form.type.value,
                this.form.difficulty.value,
                this.form.rightAnswer.value
            );
        request.subscribe(
            (data) => {
                this.changesSaved = true;
                this.loading = false;
                this.error = '';
                if (!this.itemId) {
                    this.clearForm();
                }
            },
            error => {
                this.error = error;
                this.loading = false;
            }
        );
    }

    public trackByFn(index: any, item: any) {
        return index;
    }

    private clearForm() {
        this.itemForm.reset();
        this.rightAnswer = null;
        this.newChoices = ['', '', '', '', '', '', '', ''];
    }
}
