import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { map, filter, catchError, tap, switchMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    httpOptions = {
        headers: new HttpHeaders({ 
          'Access-Control-Allow-Origin':'*'
        })
    };
    // private currentTokenSubject: BehaviorSubject<string>;
    // private currentUserSubject: BehaviorSubject<User>;
    public currentToken: string;

    constructor(private http: HttpClient) {
        this.currentToken = JSON.parse(localStorage.getItem('token'));
    }

    public login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/auth/login`, { email, password }, this.httpOptions)
            .pipe(
                filter(res => res.message === 'token_generated'),
                map(res => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('token', JSON.stringify(res.data.token));
                    this.currentToken = res.data.token;
                    return res;
                })
            );
    }

    public logout() {
        localStorage.removeItem('token');

        return this.http.delete(`${environment.apiUrl}/auth/invalidate`, {
            headers: {
                Authorization: `Bearer ${this.currentToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).pipe(map(() => {
            this.currentToken = null;
        }));
    }

    public getUser(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/auth/user`, {
            headers: { 
                Authorization: `Bearer ${this.currentToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).pipe(
            catchError((err) => {
                return of(null);
            })
        );
    }

    public getUserId(): Observable<number> {
        return this.getUser().pipe(
            catchError((err) => {
                return of(null);
            }),
            map(user => {
                return (user) ? (user as User).id : null;
            })
        );
    }

    public isAuthenticated(): string {
        return this.currentToken && localStorage.getItem('token');
    }

    public refreshToken(): Observable<User> {
        return this.http.patch<any>(`${environment.apiUrl}/auth/refresh`, {
            headers: { 
                Authorization: `Bearer ${this.currentToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).pipe(
            catchError((err) => {
                this.currentToken = null;
                localStorage.removeItem('token');
                return of(null);
            }),
            map(res => {
                if (res && res.message === 'token_refreshed') {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('token', JSON.stringify(res.data.token));
                    this.currentToken = res.data.token;
                }
                return res;
            })
        );
    }
}
